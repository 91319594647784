import React, {useCallback, useRef} from "react";
import Layout from "../layouts/en";
import { scrollElementIntoView } from "../components/helper";
import { Col, Container, Row } from "reactstrap";
import wordpress from "../assets/icons/icons8-wordpress-color.svg";
import react from "../assets/icons/icons8-react-color.svg";
import laravel from "../assets/icons/laravel.svg";
import advantage1 from "../assets/icons/emojis/laptop.png";
import advantage2 from "../assets/icons/emojis/mann-mit-ausgesetrecktem-arm.png";
import advantage3 from "../assets/icons/emojis/winkende-hand.png";
import slider1 from "../images/screenshot-philipp-seipp.png";
import slider2 from "../images/screenshot-antje-abel-architekten.png";
import slider3 from "../images/screenshot-steele-meals.png";
import step1 from "../images/pexels-photo-1311547-compressor.jpeg";
import step2 from "../images/pexels-photo-2265482-compressor.jpeg";
import step3 from "../images/photo-1557425955-df376b5903c8-compressor.jpeg";
import step4 from "../images/photo-1544006659-f0b21884ce1d-compressor.jpeg";
import header from "../images/mockup-yourproject-compressor.png";
import SectionContact from "../components/sections/sectioncontact";
import Collapsible from "react-collapsible";
import {throttle} from "lodash";

function Websites(props) {
  const scrollTop = useRef(0);
  if (typeof document !== `undefined`) {
    document.documentElement.classList.remove('nav-open');
  }
  const resizeListener = useCallback(() => {
    document.querySelectorAll('.slider-img').forEach(el => {
      let height = el.offsetWidth * 0.62;
      el.style.minHeight = height + 'px';
    });
  }, []);
  const scrollListener = useCallback(() => {
    let st = window.pageYOffset || document.documentElement.scrollTop;
    if (document.getElementById('page-slider')) {
      let scroll = document.getElementById('page-slider').getBoundingClientRect();

      if (scroll.top <= window.innerHeight && scroll.top >= -scroll.height) {
        let windowHeight = window.innerHeight;
        let windowWidth = window.innerWidth;
        let elementWidth = scroll.width;
        let elementHeight = scroll.height;
        if (st > scrollTop.current){
          scroll = scroll.bottom;
        } else {
          scroll = scroll.bottom;
        }
        let elementWidthTotal = 0;
        if (windowWidth >= 992) {
          elementWidthTotal = (elementWidth - 716) * 3 + 2 * 24;
        } else if (windowWidth >= 576) {
          elementWidthTotal = (elementWidth - 258) * 3 + 2 * 24;
        } else {
          elementWidthTotal = (elementWidth - 80) * 3 + 2 * 16;
        }
        let difference = elementWidthTotal - elementWidth;
        let heightTotal =  windowHeight + elementHeight;
        let percentage = scroll / heightTotal;
        if (percentage >= 0 && percentage <= 1) {
          let reversePercentage = 1 - percentage;
          if (st > scrollTop.current){
            document.getElementById('page-slider').style.transform = 'translateX(-' + (reversePercentage * difference) + 'px)';
          } else {
            document.getElementById('page-slider').style.transform = 'translateX(-' + (difference - (percentage * difference)) + 'px)';
          }
        }
      }
    }
    scrollTop.current = st <= 0 ? 0 : st;
  }, []);
  const loadListener = useCallback(() => {
    document.querySelectorAll('.slider-img').forEach(el => {
      let height = el.offsetWidth * 0.62;
      el.style.minHeight = height + 'px';
    });
  }, []);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    if (typeof window !== `undefined` && window.location.hash !== '') {
      if (document.getElementById(window.location.hash.replace('#', '')) !== null) {
        setTimeout(() => {
          scrollElementIntoView(document.getElementById(window.location.hash.replace('#', '')));
        }, 500);
      }
    }
    document.querySelectorAll('.slider-img').forEach(el => {
      let height = el.offsetWidth * 0.62;
      el.style.minHeight = height + 'px';
    });
    window.addEventListener('resize', throttle(resizeListener, 200));
    window.addEventListener('load', loadListener);
    window.addEventListener('scroll', throttle(scrollListener, 20));
    return () => {
      window.removeEventListener('resize', resizeListener);
      window.removeEventListener('scroll', scrollListener);
      window.removeEventListener('load', loadListener);
    }
  }, []);

  return (
    <Layout classNameChild="websites" location={props.location} title="Website agency from Karlsruhe | newww.agency" description="As a website agency from Karlsruhe, we create your individual website. Experts in consulting, design, development and support of websites and landing pages." keywords="digital agency, Karlsruhe, Website, Conception, UX / UI design, Development, Responsive, SEO, Wordpress, " robots="index, follow, notranslate">
      <div className="section section-dark section-header" id="websites">
        <Container>
          <Row>
            <Col lg="6" md="12" sm="12" xs="12">
              <div className="text-wrapper">
                <h3 className="header-subheadline">Individual website</h3>
                <h1 className="header-headline">As a website agency, we develop your individual website.</h1>
                <a className="create-contact dark" href="mailto:hey@newww.agency?subject=Contact request">Make contact</a>
              </div>
            </Col>
            <Col lg="6" md="12" sm="12" xs="12">
              <div className="img-wrapper" style={{backgroundImage: `url(${header})`}} />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="section section-tripple" id="advantages">
        <Container>
          <h2 className="headline">Your individual website exactly as you imagine it to be.</h2>
          <p className="description">Our services include the conception, design and development of flexible, powerful, and user-friendly websites that inspire users and trigger a wow.</p>
          <Row>
            <Col lg="4" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${advantage1})`}}/>
                <h3 className="box-title">100% custom design</h3>
                <p className="box-description">Together with you, we will develop your individual website that is tailored precisely to you and your brand.</p>
              </div>
            </Col>
            <Col lg="4" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${advantage2})`}}/>
                <h3 className="box-title">Maximum flexibility</h3>
                <p className="box-description">We build your website in such a way that you then encounter maximum flexibility and can easily make adjustments.</p>
              </div>
            </Col>
            <Col lg="4" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${advantage3})`}}/>
                <h3 className="box-title">All from a single source</h3>
                <p className="box-description">With us you get all services from a single source. We think of all our projects holistically and can do everything that has to do with the web.</p>
              </div>
            </Col>
          </Row>
          <a className="create-contact" href="mailto:hey@newww.agency?subject=Contact request">Make contact</a>
        </Container>
      </div>
      <div className="section section-dark section-slider" id="slider">
        <Container>
          <h2 className="headline">We love our customers and work closely with them.</h2>
          <p className="description">In each of our projects, we rely on close cooperation with our customers. The focus is always on the future user, whom we want to offer a unique shopping experience.</p>
          <div className="slider" id="page-slider">
            <div className="slider-img" style={{backgroundImage: `url(${slider1})`}}/>
            <div className="slider-img" style={{backgroundImage: `url(${slider2})`}}/>
            <div className="slider-img" style={{backgroundImage: `url(${slider3})`}}/>
          </div>
          <a className="create-contact" href="mailto:hey@newww.agency?subject=Contact request">Make contact</a>
        </Container>
      </div>
      <div className="section section-knowledge" id="knowledge">
        <Container>
          <Row>
            <Col lg="6" md="12" sm="12" xs="12">
              <div className="text-wrapper">
                <h3 className="knowledge-subheadline">Did you know that …</h3>
                <h2 className="knowledge-headline">Just 66% of companies have their own website.</h2>
                <p className="knowledge-paragraph">
                  Even if you can hardly believe it, according to the Federal Statistical Office, just 66% of all German companies have their own website. And this even though over 80% of Internet users search and find their products and services via the Internet. So, it's time to deal with the topic.
                </p>
                <a className="create-contact" href="mailto:hey@newww.agency?subject=Contact request">Make contact</a>
              </div>
            </Col>
            <Col lg="6" md="12" sm="12" xs="12">
              <div className="img-wrapper" />
            </Col>
          </Row>
        </Container>
      </div>
      <div className="section section-dark section-line" id="line">
        <Container>
          <h2 className="headline">With us, a website project only takes a few weeks to go live.</h2>
          <p className="description">We use agile and efficient processes in all of our projects. With us, a website project usually only takes a few weeks to go live. Our teams work hand in hand without any frictional losses.</p>
          <Row>
            <Col lg="3" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${step1})`}}/>
                <div className="box-line">
                  <div className="line" />
                  <div className="circle" />
                </div>
                <h4 className="box-title">Screening</h4>
                <p className="box-description">In the first step, we deal intensively with your project, plan the project, analyze your competitors, and evaluate customer needs.</p>
              </div>
            </Col>
            <Col lg="3" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${step2})`}}/>
                <div className="box-line">
                  <div className="line" />
                  <div className="circle" />
                </div>
                <h4 className="box-title">UX/UI Design</h4>
                <p className="box-description">It continues with the design of your website, in which you actively participate and give your input. The result is a detailed screen design for all end devices.</p>
              </div>
            </Col>
            <Col lg="3" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${step3})`}}/>
                <div className="box-line">
                  <div className="line" />
                  <div className="circle" />
                </div>
                <h4 className="box-title">Development</h4>
                <p className="box-description">Then our developers start with the implementation and give full throttle. As soon as the individual pages and functions are ready, they will be tested intensively.</p>
              </div>
            </Col>
            <Col lg="3" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${step4})`}}/>
                <div className="box-line">
                  <div className="line" />
                  <div className="circle" />
                </div>
                <h4 className="box-title">Go-Live</h4>
                <p className="box-description">Once all the content and plugins have been entered, you can start working on your website. Of course, we also look after our customers afterwards.</p>
              </div>
            </Col>
          </Row>
          <a className="create-contact" href="mailto:hey@newww.agency?subject=Contact request">Make contact</a>
        </Container>
      </div>
      <div className="section section-tripple" id="technologies">
        <Container>
          <h2 className="headline">We work with the best website systems out there.</h2>
          <p className="description">Whether Laravel, React or WordPress, we work with the best website systems available and are geared towards our customers. Our goal is always to develop websites that offer maximum flexibility.</p>
          <Row>
            <Col lg="4" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${laravel})`}}/>
                <h3 className="box-title">Laravel/Lumen</h3>
                <p className="box-description">In the backend, we rely on the PHP micro-framework Lumen from Laravel and use it to build more complex web applications.</p>
              </div>
            </Col>
            <Col lg="4" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${react})`}}/>
                <h3 className="box-title">React</h3>
                <p className="box-description">In addition to Angular, React is one of our most popular JavaScript frameworks and helps us to build unique user interfaces.</p>
              </div>
            </Col>
            <Col lg="4" md="6" sm="6" xs="12">
              <div className="box">
                <div className="box-img" style={{backgroundImage: `url(${wordpress})`}}/>
                <h3 className="box-title">Wordpress</h3>
                <p className="box-description">WordPress is a free content management system (CMS) and is one of the most widespread systems for operating websites.</p>
              </div>
            </Col>
          </Row>
          <a className="create-contact" href="mailto:hey@newww.agency?subject=Contact request">Make contact</a>
        </Container>
      </div>
      <div className="section section-dark section-faq" id="faq">
        <Container>
          <h2 className="headline">Frequently asked questions (FAQ)</h2>
          <Row>
            <Col lg="12" className="faq-wrapper">
              <ul>
                <li>
                  <Collapsible trigger="How long does a website project take?">
                    <p>
                      The length of a website project depends on a variety of different factors. One of the main roles here is whether an existing theme is used, or an individual design is implemented. However, our website projects usually only take a few weeks.
                    </p>
                  </Collapsible>
                </li>
                <li>
                  <Collapsible trigger="How much does a website cost?">
                    <p>
                      In all of our projects, we follow the budget of our customers and make it our task to get the most out of the means available. Our usual hourly rate is on average € 80.00 plus VAT. The costs therefore always depend on the complexity and scope of the project.
                    </p>
                  </Collapsible>
                </li>
                <li>
                  <Collapsible trigger="How does a website project work?">
                    <p>
                      We start each of our projects with an intensive screening by carefully examining your project. It continues with the UX / UI design. This is where the concept is worked out, which the developers then implement one-to-one. Once the content has been entered and everything has been tested, you can start.
                    </p>
                  </Collapsible>
                </li>
                <li>
                  <Collapsible trigger="Which website systems are used?">
                    <p>
                      We are flexible when it comes to the technologies we use and we focus on our customers. WordPress, which is characterized by its simplicity, is particularly popular with us. We also build websites with React, which is particularly useful when more complex animations are used.
                    </p>
                  </Collapsible>
                </li>
                <li>
                  <Collapsible trigger="Where does newww.agency offer its services?">
                    <p>
                      Our headquarters are in Karlsruhe. Our customers, on the other hand, are at home all over the world. We look after our customers across all locations and have established processes that enable flexible and smooth cooperation.
                    </p>
                  </Collapsible>
                </li>
              </ul>
            </Col>
          </Row>
          <a className="create-contact" href="mailto:hey@newww.agency?subject=Contact request">Make contact</a>
        </Container>
      </div>
      <SectionContact lang='en' />
    </Layout>
  );
}

export default Websites;
